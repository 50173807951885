//自提/FBA
export const WarehouseShipments_status = [{
		value: '',
		label: "全部",
		label_en: 'All'
	},
	
	{
		value: '10',
		label: "已提交",
		label_en: 'submitted'
	},
	{
		value: '20',
		label: "拣货中",
		label_en: 'Picking'
	},
	{
		value: '28',
		label: "备货中",
		label_en: 'play pack'
	},
	{
		value: '30',
		label: "待出库",
		label_en: 'For outbound'
	},
	{
		value: '36',
		label: "已出库",
		label_en: 'Have outbound'
	},
	{
		value: '90',
		label: "已撤销",
		label_en: 'Revoked'
	},
	// {
	// 	value: '0',
	// 	label: "草稿",
	// 	label_en: 'manuscript'
	// }
]

//退货管理
export const WarehouseExchangeReturn_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '10',
		label: '等待签收',
		label_en: 'Waiting Signature'
	},
	{
		value: '20',
		label: '部分签收',
		label_en: 'Partial Signature'
	},
	{
		value: '30',
		label: '全部签收',
		label_en: 'All signed'
	}
	,
	{
		value: '0',
		label: '草稿',
		label_en: 'manuscript'
	}
	
]

//一件代发
export const WarehouseDropShipping_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '3',
		label: '校验中',
		label_en: 'submitted'
	},
	{
		value: '10',
		label: '已提交',
		label_en: 'submitted'
	},
	{
		value: '20',
		label: '已审核',
		label_en: 'checked'
	},
	{
		value: '30',
		label: '拣货中',
		label_en: 'picking'
	},
	{
		value: '35',
		label: '待提取',
		label_en: 'To be extracted'
	},
	{
		value: '40',
		label: '已提取',
		label_en: 'Has been extracted'
	},
	{
		value: '50',
		label: '待召回|退货',
		label_en: 'Pending recall'
	},
	{
		value: '60',
		label: '拦截',
		label_en: 'intercept'
	},
	{
		value: '90',
		label: '已取消',
		label_en: 'Canceled'
	},
	// {
	// 	value: '0',
	// 	label: '草稿',
	// 	label_en: 'manuscript'
	// },
	{
		value: '5',
		label: '异常',
		label_en: 'submitted'
	},
]

//入库
export const InWarehouse_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '10',
		label: '在途中',
		label_en: 'Submitted'
	},
	{
		value: '15',
		label: '已到仓',
		label_en: 'Arrived In'
	},
	{
		value: '18',
		label: '收货中',
		label_en: 'Receiving'
	},
	{
		value: '20',
		label: '已收货',
		label_en: 'Received'
	},
	// {
	// 	value: '0',
	// 	label: '草稿',
	// 	label_en: 'manuscript'
	// }
]

//异常件浏览
export const ExceptionalRecord_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '10',
		label: '已提交',
		label_en: 'submitted'
	},
	{
		value: '30',
		label: '已签收',
		label_en: 'signed'
	}
]

//SKU拆分
export const WSkuSplit_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '0',
		label: '草稿',
		label_en: 'manuscript'
	},
	{
		value: '10',
		label: '已提交',
		label_en: 'submitted'
	},
	{
		value: '20',
		label: '处理中',
		label_en: 'being processed'
	},
	{
		value: '60',
		label: '入库中',
		label_en: 'Put in storage'
	},
	{
		value: '99',
		label: '取消',
		label_en: 'cancel'
	}
]
//SKU信息
export const WSkuInfo_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '0',
		label: '未审核',
		label_en: 'unreviewed'
	},
	{
		value: '10',
		label: '已审核',
		label_en: 'audited'
	},
]
//转运出库
export const TransportOutWh_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '0',
		label: '草稿',
		label_en: 'manuscript'
	},
	{
		value: '10',
		label: '已提交',
		label_en: 'submitted'
	},
	{
		value: '60',
		label: '拣货中',
		label_en: 'picking'
	},
	{
		value: '70',
		label: '待出库',
		label_en: 'Ready delivery'
	},
	{
		value: '80',
		label: '已出库',
		label_en: 'Out stock'
	}
]

//转运入库
export const TransportInWh_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '0',
		label: '草稿',
		label_en: 'manuscript'
	},
	{
		value: '10',
		label: '在途中',
		label_en: 'On the way'
	},
	{
		value: '15',
		label: '已到仓',
		label_en: 'Arrived In'
	},
	{
		value: '20',
		label: '已接收',
		label_en: 'already received'
	},
	{
		value: '35',
		label: '部分转运',
		label_en: 'Partial transshipment'
	},
	{
		value: '30',
		label: '已转运',
		label_en: 'transferred'
	}
]

//预约出库
export const TransportPlanOutWh_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	// {
	// 	value: '0',
	// 	label: '草稿',
	// 	label_en: 'manuscript'
	// },
	{
		value: '10',
		label: '已提交',
		label_en: 'submitted'
	},
	{
		value: '20',
		label: '预约中',
		label_en: 'Appointment in progress'
	},
	{
		value: '30',
		label: '预约完成',
		label_en: 'Appointment completed'
	},
	{
		value: '40',
		label: '已提货',
		label_en: 'Picked up'
	}
]

//快速转运入库
export const TransportFast_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '10',
		label: '在途中',
		label_en: 'On the way'
	},
	{
		value: '15',
		label: '已到仓',
		label_en: 'Arrived In'
	},
	{
		value: '20',
		label: '已接收',
		label_en: 'already received'
	},
	{
		value: '35',
		label: '部分转运',
		label_en: 'Partial transshipment'
	},
	{
		value: '30',
		label: '已转运',
		label_en: 'transferred'
	}
]

//快递面单明细
export const ExpressSheetDetList_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '10',
		label: '进行中',
		label_en: 'under way'
	},
	{
		value: '20',
		label: '已完成',
		label_en: 'completed'
	},
	{
		value: '60',
		label: '取消进行中',
		label_en: 'Cancel in progress'
	},
	{
		value: '70',
		label: '取消面单',
		label_en: 'Cancel noodle sheet'
	},
	{
		value: '90',
		label: '撤销打单',
		label_en: 'Cancel the order'
	}
]
export const ExpressSheetDetList_status2 = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '0',
		label: '未出单',
		label_en: 'Pending order'
	},
	{
		value: '1',
		label: '已出单',
		label_en: 'Out of order'
	}
]
//我的工单
export const workOrderList_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	// {
	// 	value: '0',
	// 	label: '草稿',
	// 	label_en: 'manuscript'
	// },
	{
		value: '10',
		label: '待客服处理',
		label_en: 'submitted'
	},
	{
		value: '20',
		label: '待仓库处理',
		label_en: 'acccepted'
	},
	{
		value: '40',
		label: '已完成',
		label_en: 'processed'
	},
	{
		value: '90',
		label: '退回',
		label_en: 'Return'
	}
]
//第三方订单
export const ThirdOrder_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	// {
	//   value: '0',
	//   label: this.$t('Storage.packing_list.not_receive')
	// },
	{
		value: '10',
		label: '未下单',
		label_en: 'not order'
	},
	{
		value: '20',
		label: '已下单',
		label_en: 'placed order'
	},
	{
		value: '30',
		label: '取消',
		label_en: 'cancel'
	}
]
//售后
export const QARecList_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '10',
		label: '已提交',
		label_en: 'submitted'
	},
	{
		value: '20',
		label: '受理中',
		label_en: 'under consideration'
	},
	{
		value: '30',
		label: '正常结束',
		label_en: 'Normal End'
	},
	{
		value: '40',
		label: '异常结束',
		label_en: 'abnormal end'
	}
]
//代理
export const agentUser_status = [{
		value: '',
		label: '全部',
		label_en: 'All'
	},
	{
		value: '10',
		label: '绑定',
		label_en: 'bind'
	},
	{
		value: '90',
		label: '解绑',
		label_en: 'unbind'
	},
]
//RechargeRecord
export const RechargeRecord_status = [{
		value: '',
		label: '全部'
	},
	{
		value: '10',
		label: '已提交'
	},
	{
		value: '20',
		label: '确认入账'
	}

]

